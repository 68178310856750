import { default as aboutlv6i1FL0CfMeta } from "/opt/build/repo/pages/about.vue?macro=true";
import { default as contactNfojXvLlAkMeta } from "/opt/build/repo/pages/contact.vue?macro=true";
import { default as forgot_password6CSbkRhcCOMeta } from "/opt/build/repo/pages/forgot_password.vue?macro=true";
import { default as indexlaFeZuYZkUMeta } from "/opt/build/repo/pages/index.vue?macro=true";
import { default as loginBXD9sq3T3sMeta } from "/opt/build/repo/pages/login.vue?macro=true";
import { default as mapiGX3xCd7cpMeta } from "/opt/build/repo/pages/map.vue?macro=true";
import { default as registeryZbpxRGV6SMeta } from "/opt/build/repo/pages/register.vue?macro=true";
import { default as reset_password6FafE7u2ASMeta } from "/opt/build/repo/pages/reset_password.vue?macro=true";
import { default as billingTtDfKCy67FMeta } from "/opt/build/repo/pages/settings/billing.vue?macro=true";
import { default as indexoWr0YesFbSMeta } from "/opt/build/repo/pages/settings/index.vue?macro=true";
import { default as profileNcyTuBLl16Meta } from "/opt/build/repo/pages/settings/profile.vue?macro=true";
import { default as team_membersQmQsrqoxlHMeta } from "/opt/build/repo/pages/settings/team_members.vue?macro=true";
import { default as teamsXIhNoNBIh8Meta } from "/opt/build/repo/pages/settings/teams.vue?macro=true";
import { default as settings6ZNFu1CSghMeta } from "/opt/build/repo/pages/settings.vue?macro=true";
import { default as tasksyljVs40UAMMeta } from "/opt/build/repo/pages/tasks.vue?macro=true";
import { default as trackingHTUpd7OGRpMeta } from "/opt/build/repo/pages/tracking.vue?macro=true";
import { default as verifyPPsbf6HYMxMeta } from "/opt/build/repo/pages/verify.vue?macro=true";
export default [
  {
    name: aboutlv6i1FL0CfMeta?.name ?? "about",
    path: aboutlv6i1FL0CfMeta?.path ?? "/about",
    meta: aboutlv6i1FL0CfMeta || {},
    alias: aboutlv6i1FL0CfMeta?.alias || [],
    redirect: aboutlv6i1FL0CfMeta?.redirect,
    component: () => import("/opt/build/repo/pages/about.vue").then(m => m.default || m)
  },
  {
    name: contactNfojXvLlAkMeta?.name ?? "contact",
    path: contactNfojXvLlAkMeta?.path ?? "/contact",
    meta: contactNfojXvLlAkMeta || {},
    alias: contactNfojXvLlAkMeta?.alias || [],
    redirect: contactNfojXvLlAkMeta?.redirect,
    component: () => import("/opt/build/repo/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: forgot_password6CSbkRhcCOMeta?.name ?? "forgot_password",
    path: forgot_password6CSbkRhcCOMeta?.path ?? "/forgot_password",
    meta: forgot_password6CSbkRhcCOMeta || {},
    alias: forgot_password6CSbkRhcCOMeta?.alias || [],
    redirect: forgot_password6CSbkRhcCOMeta?.redirect,
    component: () => import("/opt/build/repo/pages/forgot_password.vue").then(m => m.default || m)
  },
  {
    name: indexlaFeZuYZkUMeta?.name ?? "index",
    path: indexlaFeZuYZkUMeta?.path ?? "/",
    meta: indexlaFeZuYZkUMeta || {},
    alias: indexlaFeZuYZkUMeta?.alias || [],
    redirect: indexlaFeZuYZkUMeta?.redirect,
    component: () => import("/opt/build/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: loginBXD9sq3T3sMeta?.name ?? "login",
    path: loginBXD9sq3T3sMeta?.path ?? "/login",
    meta: loginBXD9sq3T3sMeta || {},
    alias: loginBXD9sq3T3sMeta?.alias || [],
    redirect: loginBXD9sq3T3sMeta?.redirect,
    component: () => import("/opt/build/repo/pages/login.vue").then(m => m.default || m)
  },
  {
    name: mapiGX3xCd7cpMeta?.name ?? "map",
    path: mapiGX3xCd7cpMeta?.path ?? "/map",
    meta: mapiGX3xCd7cpMeta || {},
    alias: mapiGX3xCd7cpMeta?.alias || [],
    redirect: mapiGX3xCd7cpMeta?.redirect,
    component: () => import("/opt/build/repo/pages/map.vue").then(m => m.default || m)
  },
  {
    name: registeryZbpxRGV6SMeta?.name ?? "register",
    path: registeryZbpxRGV6SMeta?.path ?? "/register",
    meta: registeryZbpxRGV6SMeta || {},
    alias: registeryZbpxRGV6SMeta?.alias || [],
    redirect: registeryZbpxRGV6SMeta?.redirect,
    component: () => import("/opt/build/repo/pages/register.vue").then(m => m.default || m)
  },
  {
    name: reset_password6FafE7u2ASMeta?.name ?? "reset_password",
    path: reset_password6FafE7u2ASMeta?.path ?? "/reset_password",
    meta: reset_password6FafE7u2ASMeta || {},
    alias: reset_password6FafE7u2ASMeta?.alias || [],
    redirect: reset_password6FafE7u2ASMeta?.redirect,
    component: () => import("/opt/build/repo/pages/reset_password.vue").then(m => m.default || m)
  },
  {
    name: settings6ZNFu1CSghMeta?.name ?? undefined,
    path: settings6ZNFu1CSghMeta?.path ?? "/settings",
    meta: settings6ZNFu1CSghMeta || {},
    alias: settings6ZNFu1CSghMeta?.alias || [],
    redirect: settings6ZNFu1CSghMeta?.redirect,
    component: () => import("/opt/build/repo/pages/settings.vue").then(m => m.default || m),
    children: [
  {
    name: billingTtDfKCy67FMeta?.name ?? "settings-billing",
    path: billingTtDfKCy67FMeta?.path ?? "billing",
    meta: billingTtDfKCy67FMeta || {},
    alias: billingTtDfKCy67FMeta?.alias || [],
    redirect: billingTtDfKCy67FMeta?.redirect,
    component: () => import("/opt/build/repo/pages/settings/billing.vue").then(m => m.default || m)
  },
  {
    name: indexoWr0YesFbSMeta?.name ?? "settings",
    path: indexoWr0YesFbSMeta?.path ?? "",
    meta: indexoWr0YesFbSMeta || {},
    alias: indexoWr0YesFbSMeta?.alias || [],
    redirect: indexoWr0YesFbSMeta?.redirect,
    component: () => import("/opt/build/repo/pages/settings/index.vue").then(m => m.default || m)
  },
  {
    name: profileNcyTuBLl16Meta?.name ?? "settings-profile",
    path: profileNcyTuBLl16Meta?.path ?? "profile",
    meta: profileNcyTuBLl16Meta || {},
    alias: profileNcyTuBLl16Meta?.alias || [],
    redirect: profileNcyTuBLl16Meta?.redirect,
    component: () => import("/opt/build/repo/pages/settings/profile.vue").then(m => m.default || m)
  },
  {
    name: team_membersQmQsrqoxlHMeta?.name ?? "settings-team_members",
    path: team_membersQmQsrqoxlHMeta?.path ?? "team_members",
    meta: team_membersQmQsrqoxlHMeta || {},
    alias: team_membersQmQsrqoxlHMeta?.alias || [],
    redirect: team_membersQmQsrqoxlHMeta?.redirect,
    component: () => import("/opt/build/repo/pages/settings/team_members.vue").then(m => m.default || m)
  },
  {
    name: teamsXIhNoNBIh8Meta?.name ?? "settings-teams",
    path: teamsXIhNoNBIh8Meta?.path ?? "teams",
    meta: teamsXIhNoNBIh8Meta || {},
    alias: teamsXIhNoNBIh8Meta?.alias || [],
    redirect: teamsXIhNoNBIh8Meta?.redirect,
    component: () => import("/opt/build/repo/pages/settings/teams.vue").then(m => m.default || m)
  }
]
  },
  {
    name: tasksyljVs40UAMMeta?.name ?? "tasks",
    path: tasksyljVs40UAMMeta?.path ?? "/tasks",
    meta: tasksyljVs40UAMMeta || {},
    alias: tasksyljVs40UAMMeta?.alias || [],
    redirect: tasksyljVs40UAMMeta?.redirect,
    component: () => import("/opt/build/repo/pages/tasks.vue").then(m => m.default || m)
  },
  {
    name: trackingHTUpd7OGRpMeta?.name ?? "tracking",
    path: trackingHTUpd7OGRpMeta?.path ?? "/tracking",
    meta: trackingHTUpd7OGRpMeta || {},
    alias: trackingHTUpd7OGRpMeta?.alias || [],
    redirect: trackingHTUpd7OGRpMeta?.redirect,
    component: () => import("/opt/build/repo/pages/tracking.vue").then(m => m.default || m)
  },
  {
    name: verifyPPsbf6HYMxMeta?.name ?? "verify",
    path: verifyPPsbf6HYMxMeta?.path ?? "/verify",
    meta: verifyPPsbf6HYMxMeta || {},
    alias: verifyPPsbf6HYMxMeta?.alias || [],
    redirect: verifyPPsbf6HYMxMeta?.redirect,
    component: () => import("/opt/build/repo/pages/verify.vue").then(m => m.default || m)
  }
]