<script setup lang="ts">
const isModalVisible = ref<boolean>();
const { stripeSuccessUrl, stripeCancelUrl } = useRuntimeConfig().public;
const loading = ref(false);
let priceId: string | undefined = undefined;
let productId: string | undefined = undefined;
useEmitter().on("subscribe", (payload) => {
  priceId = payload.priceId;
  productId = payload.productId;
  isModalVisible.value = true;
});
const numberOfAgent = ref(5);

const subscribe = () => {
  if (!priceId || !productId) {
    return;
  }
  useNuxtApp()
    .$api("/stripe/subscribe", {
      method: "post",
      body: {
        number_of_agent: numberOfAgent.value,
        stripe_success_url: stripeSuccessUrl,
        stripe_cancel_url: stripeCancelUrl,
        price_id: priceId,
        product_id: productId,
      },
    })
    .then((res) => {
      navigateTo(res.url, { external: true });
    });
};
</script>

<template>
  <UDashboardModal
    v-model="isModalVisible"
    :title="$t('addAgentToYourTeam')"
    icon="i-mdi-account-hard-hat-outline"
    prevent-close
    :close-button="null"
    :ui="{
      footer: {
        base: 'ml-16',
      } as any,
    }"
  >
    <template #description>
      <CoreInputForm
        v-model="numberOfAgent"
        class="flex-1"
        :label="$t('numberOfAgent')"
        type="number"
        name="numberOfAgent"
      ></CoreInputForm>
    </template>
    <template #footer>
      <UButton
        :label="$t('goToPayment')"
        :loading="loading"
        @click="subscribe"
      />
      <UButton
        color="white"
        :label="$t('cancel')"
        @click="isModalVisible = false"
      />
    </template>
  </UDashboardModal>
</template>
