<script setup lang="ts">
const isModalVisible = ref<boolean>();
const { deleteTask } = useTeamsStore();
let taskId: string | undefined = undefined;
let taskNumber: number | undefined = undefined;

useEmitter().on("task:delete", (payload) => {
  taskId = payload.taskId;
  taskNumber = payload.taskNumber;
  isModalVisible.value = true;
});
const loading = ref(false);
const _deleteTask = () => {
  if (!taskId) {
    return;
  }
  loading.value = true;
  deleteTask(taskId)
    .then(() => (isModalVisible.value = false))
    .finally(() => (loading.value = false));
};
</script>

<template>
  <UDashboardModal
    v-model="isModalVisible"
    :title="$t('deleteTask')"
    :description="$t('areYouSureDeleteTask', { taskNumber })"
    icon="i-heroicons-exclamation-circle"
    prevent-close
    :close-button="null"
    :ui="{
      icon: {
        base: 'text-red-500 dark:text-red-400',
      } as any,
      footer: {
        base: 'ml-16',
      } as any,
    }"
  >
    <template #footer>
      <UButton
        color="red"
        :label="$t('delete')"
        :loading="loading"
        @click="_deleteTask"
      />
      <UButton
        color="white"
        :label="$t('cancel')"
        @click="isModalVisible = false"
      />
    </template>
  </UDashboardModal>
</template>
