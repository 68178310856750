import type { CreateTaskData } from "../entities/out/create_task_data";
import type { CustomField, Task } from "../entities/tasks";
import type { TasksWithPaging } from "../entities/tasks_with_paging";
import type { Team } from "../entities/team";
import type { TaskStatus } from "../entities/types/task_status";

export const useTeamsStore = defineStore("teamsStore", () => {
  //  Filter
  const selectedStatus = ref<TaskStatus[]>([]);
  const taskFilterText = ref<string | undefined>(undefined);
  const taskFilterUserId = ref<string[]>([]);
  //End filter

  const { $api } = useNuxtApp();
  const { user } = useAuth();
  const tasksCount = ref(0);
  const { loadAgentInfo } = useTeamMembersStore();
  const taskWithPaging = ref<TasksWithPaging>();
  const tasks = () => taskWithPaging.value?.data ?? [];
  const computedTasks: ComputedRef<Task[]> = computed(() => {
    return taskWithPaging.value?.data ?? [];
  });
  const isThereMoreTasks: ComputedRef<boolean> = computed(() => {
    return taskWithPaging.value?.next_cursor ? true : false;
  });
  // used to show info window on the map when creating a new task
  const openedTaskId = ref<string>();
  const teams = ref<Team[]>([]);

  // It's directly related to the select team by v-model (two ways binding) in the layout page
  const teamId = ref<string>();
  const customerId = ref<string>();

  const fetchTasksWithPaging = async () => {
    const data = await $api<TasksWithPaging>("/management/tasks", {
      params: {
        "status[]": selectedStatus.value,
        txt: taskFilterText.value,
        "user_id[]": taskFilterUserId.value,
      },
    });
    taskWithPaging.value = data ?? undefined;
  };

  const fetchTotalTasksCount = async () => {
    $api<number>("/dashboard/tasks/count").then(
      (count) => (tasksCount.value = count),
    );
  };

  const createTask = async (state: Partial<CreateTaskData>) => {
    console.log(state);
    if (!state.receiver_city) {
      state.receiver_city = "Unknown city";
    }
    if (!state.receiver_country) {
      state.receiver_country = "Unknown country";
    }
    const task: Task = await $api("/management/tasks", {
      body: { ...state },
      method: "POST",
    });
    await nextTick();
    fetchTasksWithPaging();
    fetchTotalTasksCount();
    openedTaskId.value = task.id;
  };

  const editTask = async (state: Partial<CreateTaskData>, taskId: string) => {
    const task: Task = await $api(`/management/tasks/${taskId}`, {
      body: { ...state },
      method: "PATCH",
    });
    const index = selectTaskIndex(taskId);
    if (index > -1) {
      tasks().splice(index, 1, task);
    }
  };

  const selectTask = (taskId: string): Task | undefined => {
    return tasks().find((t) => t.id == taskId);
  };

  const selectTaskIndex = (taskId: string): number => {
    const index = tasks().findIndex((t) => t.id == taskId);
    return index;
  };

  const loadTeams = async () => {
    const data = await $api<Team[]>("/teams");
    teams.value = data ?? undefined;
    teamId.value = teams.value ? teams.value[0].id : undefined;
  };

  const switchTeam = async () => {
    await $api<Team[]>(`/teams/${teamId.value}/switch`, {
      method: "POST",
    });
  };

  const loadMoreTasks = async () => {
    const nextCursor = taskWithPaging.value?.next_cursor;
    if (nextCursor) {
      const url = `/management/tasks?cursor=${nextCursor}`;
      const data = await $api<TasksWithPaging>(url);
      const loadedTask: Task[] = data?.data || [];

      if (taskWithPaging.value) {
        tasks().push(...loadedTask);
        taskWithPaging.value.next_cursor = data?.next_cursor || undefined;
      }
    }
  };

  const saveFieldsForTask = async (
    taskId: string,
    customFields: CustomField[],
  ) => {
    await $api(`/management/tasks/${taskId}/custom-fields`, {
      body: { ...customFields },
      method: "PUT",
    });
    const task = selectTask(taskId);
    if (task) {
      task.custom_fields = customFields;
    }
  };

  const deleteTask = async (taskId: string) => {
    await $api(`/management/tasks/${taskId}`, {
      method: "DELETE",
    });
    const index = selectTaskIndex(taskId);
    if (index > -1) {
      tasks().splice(index, 1);
    }
    fetchTasksWithPaging();
  };

  const deleteTeam = async (teamId: string) => {
    await $api(`/management/teams/${teamId}`, { method: "Delete" });
    const index = teams.value.findIndex((t) => t.id == teamId);
    if (index > -1) {
      teams.value.splice(index, 1);
    }
  };

  const getTaskFields = (taskId: string): CustomField[] => {
    const task = tasks().find((t) => t.id == taskId);
    if (task) {
      return task.custom_fields;
    }
    return [];
  };

  const $reset = () => {
    teams.value = [];
    taskWithPaging.value = undefined;
    teamId.value = undefined;
  };

  watch(
    teamId,
    () => {
      if (teamId.value) {
        const team = teams.value.find((t) => t.id == teamId.value);
        if (team) {
          customerId.value = team.customer_id;
        }
        switchTeam().then(async () => {
          await nextTick();
          loadAgentInfo();
          fetchTasksWithPaging();
          fetchTotalTasksCount();
        });
      }
    },

    { immediate: true },
  );

  watch(
    user,
    () => {
      const isLoggedIn = useAuth().loggedIn.value;
      if (isLoggedIn) {
        loadTeams();
      }
    },

    { immediate: true },
  );

  watch([selectedStatus, taskFilterText, taskFilterUserId], () => {
    const isLoggedIn = useAuth().loggedIn.value;
    if (isLoggedIn) {
      fetchTasksWithPaging();
    }
  });

  return {
    loadTeams,
    loadMoreTasks,
    selectTask,
    isThereMoreTasks,
    taskWithPaging,
    editTask,
    saveFieldsForTask,
    deleteTask,
    deleteTeam,
    getTaskFields,
    tasksCount,
    computedTasks,
    customerId,
    teamId,
    teams,
    fetchTasksWithPaging,
    createTask,
    openedTaskId,
    $reset,
    selectedStatus,
    taskFilterText,
    taskFilterUserId,
  };
});
