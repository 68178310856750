<script setup lang="ts">
import { z } from "zod";
import type { CustomField } from "../../entities/tasks";
import type { SelectDataSourceType } from "../../entities/types/select_data_source_type";
const { saveFieldsForTask, getTaskFields } = useTeamsStore();
const isModallVisible = ref<boolean>();

const state = ref<CustomField[]>([]);
const toast = useToast();
const { t } = useI18n();
const emitter = useEmitter();
let taskId: string | undefined = undefined;
emitter.on("task:editCustomFields", (payload) => {
  isModallVisible.value = true;
  taskId = payload.taskId;
  state.value = [...getTaskFields(taskId)];
});

// Add new row
const addField = () => {
  const field: CustomField = {
    type: "input",
    required: "1",
    label: "",
    id: `id_${state.value.length}`,
  };
  state.value.push(field);
};

const _save = async () => {
  try {
    if (!taskId) {
      return;
    }
    await saveFieldsForTask(taskId, [...state.value]);
    toast.add({ title: t("saved") });
    isModallVisible.value = false;
  } catch (e) {
    toast.add({ title: t("Internal server error") });
  }
};

// Remove row
const deleteField = (item: CustomField) => {
  state.value = state.value.filter((t) => t != item);
};

const fieldTypes: SelectDataSourceType = [
  { text: "Input", value: "input" },
  { text: "Image", value: "image" },
  { text: "Images", value: "images" },
  { text: "Signature", value: "signature" },
];

const requiredDataSource: ComputedRef<SelectDataSourceType> = computed(() => [
  { text: t("yes"), value: "1" },
  { text: t("no"), value: "0" },
]);

const form = ref();

const schema = z
  .object({
    label: z.string().min(2),
    id: z.string().min(1),
  })
  .array();
async function onSubmit() {
  _save();
}

function onError(error: any) {
  console.error(error);
}
</script>

<template>
  <UModal v-model="isModallVisible" :ui="{ width: 'min-w-[700px]' }">
    <UForm
      ref="form"
      :schema="schema"
      :state="state"
      class="space-y-4"
      @error="onError"
      @submit="onSubmit"
    >
      <UCard
        :ui="{
          ring: '',
          divide: 'divide-y divide-gray-100 dark:divide-gray-800',
        }"
      >
        <template #header>
          {{ $t("EditTaskFields") }}
        </template>

        <div
          v-for="(item, index) in state"
          :key="item.id"
          class="flex items-start"
        >
          <!-- Label -->
          <CoreInputForm
            v-model="item.id"
            class="flex-1 m-2"
            :label="$t('id')"
            readonly
            type="text"
            :name="`${index}.id`"
          ></CoreInputForm>
          <!-- Label -->

          <CoreInputForm
            v-model="item.label"
            class="flex-1 m-2"
            label="Label"
            type="text"
            :name="`${index}.label`"
          ></CoreInputForm>

          <!-- End Label -->

          <!-- type -->
          <CoreSelectForm
            v-model="item.type"
            class="flex-1 m-2"
            label="Type"
            name="type"
            :data-source="fieldTypes"
          ></CoreSelectForm>

          <!-- End type -->

          <!-- Required -->
          <CoreSelectForm
            v-model="item.required"
            class="flex-1 m-2"
            label="Required"
            name="required"
            :data-source="requiredDataSource"
          ></CoreSelectForm>

          <!-- Required -->

          <!-- Add field -->
          <div class="flex-1 m-2 mt-8">
            <CoreButton error @click="deleteField(item)">{{
              $t("delete")
            }}</CoreButton>
          </div>

          <!-- End add field -->
        </div>

        <div>
          <!-- Add field -->
          <CoreButton class="m-1" @click="addField()">{{
            $t("addField")
          }}</CoreButton>
          <!-- End add field -->
        </div>

        <template #footer>
          <!-- Create task button -->
          <CoreButton class="m-1" type="submit">{{ $t("save") }}</CoreButton>
          <!-- End create task button -->

          <!-- Cancel  button -->
          <CoreButton light class="m-1" @click="isModallVisible = false">{{
            $t("cancel")
          }}</CoreButton>

          <!-- End Cancel  button -->
        </template>
      </UCard>
    </UForm>
  </UModal>
</template>
