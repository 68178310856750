import { ofetch } from "ofetch";
export default defineNuxtPlugin({
  name: "api-plugin",
  setup() {
    const baseURL: string = useRuntimeConfig().public.baseURL as string;
    const api = ofetch.create({
      baseURL,
      onRequest: (req) => {
        const accessToken = useCookie("auth:token");
        req.options.headers = accessToken.value
          ? {
              Authorization: ("Bearer " + accessToken.value) as string,
              "Content-Type": "application/json; charset=utf-8",
              Accept: "application/json",
            }
          : {};
      },
      onResponseError: (error) => {
        const statusCode: number = error.response.status;
        if (statusCode == 401) {
          const { logout } = useAuth();
          logout();
        }
        throw error;
      },
    });

    globalThis.$myApi = api;

    return {
      provide: {
        api,
      },
    };
  },
});
