export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.directive("disable-all", {
    mounted(el, binding) {
      if (!binding.value) return;
      const tags = ["input", "button", "textarea", "select"];
      tags.forEach((tagName) => {
        const elements = el.getElementsByTagName(tagName);
        for (let i = 0; i < elements.length; i++) {
          elements[i].disabled = true;
          elements[i].tabIndex = -1;
        }
      });
    },
  });
});
