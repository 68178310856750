<script setup lang="ts">
const isModalVisible = ref<boolean>();
const { deleteTeam } = useTeamsStore();
let teamId: string | undefined = undefined;
let teamName: string | undefined = undefined;

useEmitter().on("team:delete", (payload) => {
  teamId = payload.teamId;
  teamName = payload.teamName;
  isModalVisible.value = true;
});
const loading = ref(false);

const _deleteTeam = () => {
  if (!teamId) {
    return;
  }
  loading.value = true;
  deleteTeam(teamId)
    .then(() => (isModalVisible.value = false))
    .finally(() => (loading.value = false));
};
</script>

<template>
  <UDashboardModal
    v-model="isModalVisible"
    :title="$t('deleteTeam')"
    :description="$t('areYouSureDeleteTeam', { teamName })"
    icon="i-heroicons-exclamation-circle"
    prevent-close
    :close-button="null"
    :ui="{
      icon: {
        base: 'text-red-500 dark:text-red-400',
      } as any,
      footer: {
        base: 'ml-16',
      } as any,
    }"
  >
    <template #footer>
      <UButton
        color="red"
        :label="$t('delete')"
        :loading="loading"
        @click="_deleteTeam"
      />
      <UButton
        color="white"
        :label="$t('cancel')"
        @click="isModalVisible = false"
      />
    </template>
  </UDashboardModal>
</template>
