import { defineNuxtPlugin } from '#app/nuxt'
import { LazySvgoAgent, LazySvgoArrow, LazySvgoBoxAdd, LazySvgoBoxRemove, LazySvgoBoxTick, LazySvgoBoxTime, LazySvgoBox, LazySvgoClipboardClose, LazySvgoClipboardTick, LazySvgoCloudConnection, LazySvgoCongratulations, LazySvgoContacts, LazySvgoCopy, LazySvgoCube, LazySvgoDocumentText, LazySvgoEmptyNotifications, LazySvgoEmptyTask, LazySvgoFailedMarker, LazySvgoFilter, LazySvgoGalleryAdd, LazySvgoGoogleMaps, LazySvgoGroup, LazySvgoHome, LazySvgoInformationBoxOutline, LazySvgoInvoiceTextCheck, LazySvgoLanguage, LazySvgoLocation, LazySvgoLogin, LazySvgoLogo, LazySvgoLogout, LazySvgoMicrosoftTeams, LazySvgoNotification, LazySvgoNotification2, LazySvgoPasswordIcon, LazySvgoProfile, LazySvgoProfile2, LazySvgoRefresh, LazySvgoRouting2, LazySvgoSliderBackground, LazySvgoSorry, LazySvgoStatus, LazySvgoTimerPause, LazySvgoTimerStart, LazySvgoTimer, LazyIcon, LazyIconCSS } from '#components'
const lazyGlobalComponents = [
  ["SvgoAgent", LazySvgoAgent],
["SvgoArrow", LazySvgoArrow],
["SvgoBoxAdd", LazySvgoBoxAdd],
["SvgoBoxRemove", LazySvgoBoxRemove],
["SvgoBoxTick", LazySvgoBoxTick],
["SvgoBoxTime", LazySvgoBoxTime],
["SvgoBox", LazySvgoBox],
["SvgoClipboardClose", LazySvgoClipboardClose],
["SvgoClipboardTick", LazySvgoClipboardTick],
["SvgoCloudConnection", LazySvgoCloudConnection],
["SvgoCongratulations", LazySvgoCongratulations],
["SvgoContacts", LazySvgoContacts],
["SvgoCopy", LazySvgoCopy],
["SvgoCube", LazySvgoCube],
["SvgoDocumentText", LazySvgoDocumentText],
["SvgoEmptyNotifications", LazySvgoEmptyNotifications],
["SvgoEmptyTask", LazySvgoEmptyTask],
["SvgoFailedMarker", LazySvgoFailedMarker],
["SvgoFilter", LazySvgoFilter],
["SvgoGalleryAdd", LazySvgoGalleryAdd],
["SvgoGoogleMaps", LazySvgoGoogleMaps],
["SvgoGroup", LazySvgoGroup],
["SvgoHome", LazySvgoHome],
["SvgoInformationBoxOutline", LazySvgoInformationBoxOutline],
["SvgoInvoiceTextCheck", LazySvgoInvoiceTextCheck],
["SvgoLanguage", LazySvgoLanguage],
["SvgoLocation", LazySvgoLocation],
["SvgoLogin", LazySvgoLogin],
["SvgoLogo", LazySvgoLogo],
["SvgoLogout", LazySvgoLogout],
["SvgoMicrosoftTeams", LazySvgoMicrosoftTeams],
["SvgoNotification", LazySvgoNotification],
["SvgoNotification2", LazySvgoNotification2],
["SvgoPasswordIcon", LazySvgoPasswordIcon],
["SvgoProfile", LazySvgoProfile],
["SvgoProfile2", LazySvgoProfile2],
["SvgoRefresh", LazySvgoRefresh],
["SvgoRouting2", LazySvgoRouting2],
["SvgoSliderBackground", LazySvgoSliderBackground],
["SvgoSorry", LazySvgoSorry],
["SvgoStatus", LazySvgoStatus],
["SvgoTimerPause", LazySvgoTimerPause],
["SvgoTimerStart", LazySvgoTimerStart],
["SvgoTimer", LazySvgoTimer],
["Icon", LazyIcon],
["IconCSS", LazyIconCSS],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
