<script setup lang="ts">
const isModalVisible = ref<boolean>();
const { removeMemberFromCurrentTeam } = useTeamMembersStore();
let userId: string | undefined = undefined;
let name: string | undefined = undefined;
const loading = ref(false);
useEmitter().on("user:delete", (payload) => {
  userId = payload.userId;
  name = payload.name;
  isModalVisible.value = true;
});

const _removeMember = () => {
  if (!userId) {
    return;
  }
  loading.value = true;
  removeMemberFromCurrentTeam(userId)
    .then(() => (isModalVisible.value = false))
    .finally(() => (loading.value = false));
};
</script>

<template>
  <UDashboardModal
    v-model="isModalVisible"
    :title="$t('deleteMember')"
    :description="$t('areYouSureDeleteTeamMember', { memberName: name })"
    icon="i-heroicons-exclamation-circle"
    prevent-close
    :close-button="null"
    :ui="{
      icon: {
        base: 'text-red-500 dark:text-red-400',
      } as any,
      footer: {
        base: 'ml-16',
      } as any,
    }"
  >
    <template #footer>
      <UButton
        color="red"
        :label="$t('delete')"
        :loading="loading"
        @click="_removeMember"
      />
      <UButton
        color="white"
        :label="$t('cancel')"
        @click="isModalVisible = false"
      />
    </template>
  </UDashboardModal>
</template>

<!-- <div>
  <UModal v-model="isModalVisible">
    <UCard
      :ui="{
        ring: '',
        divide: 'divide-y divide-gray-100 dark:divide-gray-800',
      }"
    >
      {{ $t("areYouSureDeleteTeamMember", { memberName: name }) }}
      <template #footer>
        <CoreButton
          color="red"
          class="ml-2"
          :label="$t('yes')"
          @click="_removeMember"
        ></CoreButton>
        <CoreButton
          class="ml-2"
          color="gray"
          :label="$t('cancel')"
          @click="isModalVisible = false"
        ></CoreButton>
      </template>
    </UCard>
  </UModal>
</div> -->
