<script lang="ts" setup>
import type { SelectDataSourceType } from "../../entities/types/select_data_source_type";

const model = defineModel<any>();
defineProps<{
  dataSource: SelectDataSourceType;
  label: string;
  name: string;
  placeHolder?: string;
  multiSelect?: boolean;
}>();
</script>

<template>
  <CoreForm class="p-1" :label="label" :name="name">
    <CoreSelect
      v-model="model"
      :multiple="multiSelect"
      :options="dataSource"
      :placeholder="placeHolder"
      value-attribute="value"
      option-attribute="text"
    />
  </CoreForm>
</template>
