export type ApplicationEvents = {
  // Tasks
  "task:create": undefined;
  "task:duplicate": { taskId: string };
  "task:edit": { taskId: string };
  "task:delete": { taskId: string; taskNumber: number };
  "task:show": { taskId: string };
  "task:createOnTheMap": { lng: number; lat: number };
  // End task
  subscribe: { productId: string; priceId: string };
  // buy agent

  //
  // Task Custom fields
  "task:editCustomFields": { taskId: string };
  // End task custom fields

  //User
  "user:invite": undefined;
  "user:edit": { userId: string };
  "user:delete": { userId: string; name: string };
  //End user

  //Team
  "team:create": undefined;
  "team:edit": { teamId: string };
  "team:delete": { teamId: string; teamName: string };
  //End user
};

// composables/useEmitter.ts
export const useEmitter = () => {
  const hooks = useNuxtApp().hooks;
  return {
    emit: <T extends keyof ApplicationEvents>(
      eventName: T,
      payload: ApplicationEvents[T],
    ) => {
      hooks.callHook(eventName as any, payload);
    },
    on: <T extends keyof ApplicationEvents>(
      eventName: T,
      handler: (payload: ApplicationEvents[T]) => void,
    ) => hooks.hook(eventName as any, handler),
  };
};
