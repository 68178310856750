<script setup lang="ts">
const isOpen = defineModel<boolean>("isDeleteTaskModalVisible");
// const props = defineProps<{ callBack: void }>();
// Private variables

let resolvePromise: (val: boolean) => void;

const showModal = async () => {
  isOpen.value = true;
  const myPromise: Promise<boolean> = new Promise((resolve) => {
    resolvePromise = resolve;
  });
  return await myPromise;
};

const confirm = () => {
  resolvePromise(true); //
  isOpen.value = false;
};
const hideModal = () => {
  resolvePromise(false);
  isOpen.value = false;
};
defineExpose({ showModal });
</script>

<template>
  <div>
    <UModal v-model="isOpen">
      <UCard
        :ui="{
          ring: '',
          divide: 'divide-y divide-gray-100 dark:divide-gray-800',
        }"
      >
        <slot></slot>
        <template #footer>
          <CoreButton
            class="ml-2"
            color="red"
            :label="$t('yes')"
            @click="confirm"
          ></CoreButton>
          <CoreButton
            class="ml-2"
            color="gray"
            :label="$t('cancel')"
            @click="hideModal"
          ></CoreButton>
        </template>
      </UCard>
    </UModal>
  </div>
</template>
