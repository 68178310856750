<script lang="ts" setup>
import { z } from "zod";
const state = ref<{ name: string }>({ name: "" });
const { $api } = useNuxtApp();
const schema = z.object({
  name: z.string().min(3),
});
const toast = useToast();
const { t } = useI18n();
const isModallVisible = defineModel<boolean>();
const { loadTeams } = useTeamsStore();
const onSubmit = async () => {
  try {
    await $api("/management/teams", {
      body: { ...state.value },
      method: "POST",
    });
    isModallVisible.value = false;
    toast.add({ title: t("saved") });
    loadTeams();
  } catch (error) {
    const result = convertBackendErrorToFormMsg(error);
    form.value.setErrors(result);
  }
};
const form = ref();
const emitter = useEmitter();

emitter.on("team:create", () => {
  isModallVisible.value = true;
});

emitter.on("team:edit", () => {
  isModallVisible.value = true;
});
</script>

<template>
  <UModal v-model="isModallVisible">
    <UForm ref="form" :schema="schema" :state="state" @submit="onSubmit">
      <UCard
        :ui="{
          ring: '',
          divide: 'divide-y divide-gray-100 dark:divide-gray-800',
        }"
      >
        <template #header>
          {{ $t("createTeam") }}
        </template>

        <CoreInputForm
          v-model="state.name"
          type="text"
          name="name"
          :label="$t('name')"
        ></CoreInputForm>

        <template #footer>
          <!-- Create task button -->
          <CoreButton
            class="m-1"
            :label="$t('save')"
            type="submit"
          ></CoreButton>
          <!-- End create task button -->

          <!-- Cancel  button -->
          <CoreButton
            light
            class="m-1"
            :label="$t('cancel')"
            @click="isModallVisible = false"
          ></CoreButton>

          <!-- End Cancel  button -->
        </template>
      </UCard>
    </UForm></UModal
  >
</template>
