import routerOptions0 from "/opt/build/repo/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@unocss+reset@0.60.2_eslint@8.57.0_floating-vue@5.2.2_ro_qhwxw25worliosedcaoyxxe6ae/node_modules/nuxt/dist/pages/runtime/router.options";
import routerOptions1 from "/opt/build/repo/node_modules/.pnpm/@nuxt+ui-pro@1.2.0_nuxt@3.11.2_rollup@4.17.2_vite@5.2.11_vue@3.4.27/node_modules/@nuxt/ui-pro/modules/pro/runtime/app/router.options.ts";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}