<script setup lang="ts">
const { fetchUserInfo } = useAuth();
const cookieAccessToken = useCookie("auth:token");

onMounted(async () => {
  watch(
    cookieAccessToken,
    async () => {
      if (!cookieAccessToken.value) {
        return;
      }
      await fetchUserInfo();
      await nextTick();
      navigateTo("/");
    },
    { immediate: true },
  );
});
</script>

<template>
  <div>
    <NuxtLoadingIndicator />
    <NuxtLayout>
      <NuxtPage></NuxtPage>
    </NuxtLayout>
    <UNotifications />
    <ModalTask></ModalTask>
    <ModalTeam></ModalTeam>
    <ModalUser></ModalUser>
    <ModalEditTaskFields></ModalEditTaskFields>
    <ModalConfirmation>{{ $t("areYouSureDeleteTask") }} </ModalConfirmation>
    <ModalDeleteTask></ModalDeleteTask>
    <ModalDeleteTeamMember></ModalDeleteTeamMember>
    <ModalDeleteTeam></ModalDeleteTeam>
    <ModalSubscribe></ModalSubscribe>
  </div>
</template>
