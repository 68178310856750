<script setup lang="ts">
defineProps<{
  label?: string;
  placeHolder?: string;
  type: string;
  name: string;
  readonly?: boolean;
}>();
const model = defineModel<string | number>();
</script>
<template>
  <CoreForm class="p-2" :label="label" :name="name">
    <CoreInput
      v-model="model"
      :readonly
      :placeholder="placeHolder"
      :type="type"
      step="any"
    />
    <slot></slot>
  </CoreForm>
</template>
