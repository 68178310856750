<script lang="ts" setup>
import type {
  AddressComponent,
  GeocodingResponse,
  Geometry,
} from "../../entities/geocoding_response";
const key = useRuntimeConfig().public.googleMapAPI;
defineProps<{
  label: string;
  name: string;
  placeHolder?: string;
}>();

const loadingAddress = ref(false);
//  Choosed address
const selectedAddressModal = defineModel<{
  formatted_address: string;
  geometry?: Geometry;
  address_components?: AddressComponent[];
}>();

const search = async (q: string): Promise<any> => {
  if (!q) {
    return [];
  }
  loadingAddress.value = true;

  const geocodingResponse = await $fetch<GeocodingResponse>(
    "https://maps.googleapis.com/maps/api/geocode/json",
    { params: { address: q, key } },
  );

  loadingAddress.value = false;
  const addresses: { formatted_address: string; geometry: Geometry }[] =
    geocodingResponse.results.map((t) => {
      console.log(t);
      return {
        formatted_address: t.formatted_address,
        geometry: t.geometry,
        address_components: t.address_components,
      };
    });

  return addresses;
};
const inputEvent = (event: any) => {
  const value: string = event.target.value;
  selectedAddressModal.value = { formatted_address: value };
};
</script>

<template>
  <UFormGroup class="w-full p-1" :label="label" :name="name">
    <UInputMenu
      v-model="selectedAddressModal"
      class="w-full"
      :debounce="2000"
      :search="search || []"
      :loading="loadingAddress"
      :placeholder="placeHolder"
      option-attribute="formatted_address"
      trailing
      by="geometry"
      @input="inputEvent"
    />
  </UFormGroup>
</template>
