<script lang="ts" setup>
import { z } from "zod";
import type { PlanInfo } from "../../entities/types/plan_Info";

const loading = ref(false);

const state = ref<{ email: string; role: string; plan: string; name: string }>({
  email: "",
  name: "",
  role: "agent",
  plan: "",
});
const { $api } = useNuxtApp();
const schema = z.object({
  email: z.string().min(3).email(),
  role: z.string(),
  plan: z.string(),
});
const { loadAgentInfo } = useTeamMembersStore();
const toast = useToast();
const { t } = useI18n();
const isModalVisible = defineModel<boolean>();
const emitter = useEmitter();
emitter.on("user:invite", () => {
  isModalVisible.value = true;
  resetForm();
  loadPlans();
});

const resetForm = () => {
  state.value.name = "";
  state.value.email = "";
  state.value.plan = "";
};

const onSubmit = async () => {
  try {
    loading.value = true;
    await $api("/management/users", {
      body: { ...state.value },
      method: "POST",
    });
    isModalVisible.value = false;
    toast.add({ title: t("saved") });
    loadAgentInfo();
  } catch (error) {
    const result = convertBackendErrorToFormMsg(error);
    form.value.setErrors(result);
  } finally {
    loading.value = false;
  }
};
const form = ref();
const roleOptions = [
  { text: t("agent"), value: "agent" },
  { text: t("manager"), value: "manager" },
];

const planOptions = ref<any[]>([]);
const plans = ref<PlanInfo[]>([]);
const getQuantiyByPlanName = (name: string): number => {
  return plans.value.find((plan) => plan.name == name)?.quantity || 0;
};
const getcurrentNumberOfAgentByPlan = (name: string): number => {
  return (
    plans.value.find((plan) => plan.name == name)?.currentNumberOfAgent || 0
  );
};

const loadPlans = () => {
  $api<PlanInfo[]>("stripe/maxAgentByPlans")
    .then((result) => {
      plans.value = result;

      planOptions.value = [
        {
          text: ` ${t("free")} (${getcurrentNumberOfAgentByPlan("free")} of ${getQuantiyByPlanName("free")})`,
          value: "free",
          disabled:
            getcurrentNumberOfAgentByPlan("free") >=
            getQuantiyByPlanName("free"),
        },
        {
          text: `${t("standard")} (${getcurrentNumberOfAgentByPlan("standard")} of ${getQuantiyByPlanName("standard")})`,
          value: "standard",
          disabled:
            getcurrentNumberOfAgentByPlan("standard") >=
            getQuantiyByPlanName("standard"),
        },
        {
          text: `${t("premium")} (${getcurrentNumberOfAgentByPlan("premium")} of ${getQuantiyByPlanName("premium")})`,
          value: "premium",
          disabled:
            getcurrentNumberOfAgentByPlan("premium") >=
            getQuantiyByPlanName("premium"),
        },
      ];
    })
    .finally(() => {
      loading.value = false;
    });
};
</script>

<template>
  <UDashboardModal
    v-model="isModalVisible"
    :title="$t('inviteMember')"
    :description="$t('Inviting new member to the team')"
    icon="i-mdi-account-hard-hat-outline"
  >
    <UForm ref="form" :schema="schema" :state="state" @submit="onSubmit">
      <CoreInputForm
        v-model="state.name"
        type="text"
        name="name"
        :label="$t('name')"
      ></CoreInputForm>

      <CoreInputForm
        v-model="state.email"
        type="text"
        name="email"
        :label="$t('email')"
      ></CoreInputForm>

      <CoreForm name="role" :label="$t('role')">
        <CoreSelect v-model="state.role" :options="roleOptions"></CoreSelect>
      </CoreForm>

      <CoreForm name="plan" :label="$t('plan')">
        <CoreSelect v-model="state.plan" :options="planOptions"></CoreSelect>
      </CoreForm>

      <div class="flex justify-end gap-3">
        <!-- Cancel  button -->
        <CoreButton
          color="white"
          :label="$t('cancel')"
          @click="isModalVisible = false"
        ></CoreButton>
        <!-- End Cancel  button -->
        <CoreButton
          :loading="loading"
          :label="$t('save')"
          type="submit"
        ></CoreButton>
        <!-- End create task button -->
      </div>
    </UForm>
  </UDashboardModal>
</template>
