import type { AddressComponent } from "../entities/geocoding_response";
const key = () => useRuntimeConfig().public.googleMapAPI;

export const convertBackendErrorToFormMsg = (error: any) => {
  const errors = error.response._data.errors;
  const result = Object.keys(errors).map((key) => {
    return { path: key, message: errors[key].join(",") };
  });
  return result;
};

export const capitalize = (value: string) => {
  return value.replace(/(?:^|\s|-)\S/g, (x) => x.toUpperCase());
};

export const capitalizeFirstLetter = (value: string) => {
  return value.charAt(0).toUpperCase() + value.slice(1);
};

export const differenceInMinutes = (date1: Date, date2: Date): number => {
  const diffInMilliseconds = Math.abs(date2.getTime() - date1.getTime());
  const minutes = Math.floor(diffInMilliseconds / (1000 * 60));
  return minutes;
};

export const getDateOnlyFromDate = (date: Date): string => {
  const year = date.getFullYear();
  const month = ("0" + (date.getMonth() + 1)).slice(-2); // Months are zero-based
  const day = ("0" + date.getDate()).slice(-2);

  // Combine them in the desired format
  const formattedDate = year + "-" + month + "-" + day;
  return formattedDate;
};

export const areDatesOnSameDate = (date1: Date, date2: Date) => {
  const d1 = new Date(date1);
  const d2 = new Date(date2);
  return (
    d1.getFullYear() === d2.getFullYear() &&
    d1.getMonth() === d2.getMonth() &&
    d1.getDate() === d2.getDate()
  );
};

export const extractAddressDetails = (
  addressComponents: AddressComponent[],
) => {
  if (addressComponents) {
    const city = addressComponents.find((adressComponent) =>
      adressComponent.types.includes("locality"),
    )?.long_name;
    const country = addressComponents.find((adressComponent) =>
      adressComponent.types.includes("country"),
    )?.long_name;
    const postalCode = addressComponents.find((adressComponent) =>
      adressComponent.types.includes("postal_code"),
    )?.long_name;
    return { city, country, postalCode };
  }
  return { city: "", country: "", postalCode: "" };
};

export const getGeocodingResponseByLatLng = async (
  latLng: any,
): Promise<{ address_components: AddressComponent[]; types: string[] }[]> => {
  const geocode = await $fetch<{
    results: { address_components: AddressComponent[]; types: string[] }[];
  }>("https://maps.googleapis.com/maps/api/geocode/json", {
    params: { latlng: latLng, key: key() },
  });
  return geocode.results;
};

export const colors = [
  "red",
  "orange",
  "yellow",
  "green",
  "teal",
  "blue",
  "indigo",
  "pink",
];
