export default defineNuxtRouteMiddleware(async (to) => {
  // Check if the route is exempt from authentication
  if (
    to.path === "/login" ||
    to.path === "/register" ||
    to.path === "/verify" ||
    to.path === "/forgot_password" ||
    to.path === "/reset_password"
  ) {
    return;
  }

  const { loggedIn, user } = await useAuth();

  if (!loggedIn.value) {
    return navigateTo("/login");
  }
  if (!user.value?.has_verified_email) {
    return navigateTo("/verify");
  }
});
