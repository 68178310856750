<script lang="ts" setup>
import type { SelectDataSourceType } from "../entities/types/select_data_source_type";
const model = defineModel<string | string[]>();
defineProps<{ label?: string }>();

const { members } = storeToRefs(useTeamMembersStore());
const usersDataSource: ComputedRef<SelectDataSourceType> = computed(() => {
  return members.value.map((agentInfo) => {
    return { text: agentInfo.user.name, value: agentInfo.user.id };
  });
});
</script>
<template>
  <!-- Agent -->
  <CoreSelect
    v-model="model"
    class="flex-1"
    :placeholder="$t('Agents')"
    :label="label"
    :options="usersDataSource"
  ></CoreSelect>
  <!-- End Agent -->
</template>
