import revive_payload_client_o0iMtc9qUL from "/opt/build/repo/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@unocss+reset@0.60.2_eslint@8.57.0_floating-vue@5.2.2_ro_qhwxw25worliosedcaoyxxe6ae/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_3G0j3ejbZm from "/opt/build/repo/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@unocss+reset@0.60.2_eslint@8.57.0_floating-vue@5.2.2_ro_qhwxw25worliosedcaoyxxe6ae/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_NbKK8gYbQ8 from "/opt/build/repo/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@unocss+reset@0.60.2_eslint@8.57.0_floating-vue@5.2.2_ro_qhwxw25worliosedcaoyxxe6ae/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_UsyfO2JDPg from "/opt/build/repo/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@unocss+reset@0.60.2_eslint@8.57.0_floating-vue@5.2.2_ro_qhwxw25worliosedcaoyxxe6ae/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_y8nMEe2xZO from "/opt/build/repo/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@unocss+reset@0.60.2_eslint@8.57.0_floating-vue@5.2.2_ro_qhwxw25worliosedcaoyxxe6ae/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_TQ69XjtBfj from "/opt/build/repo/node_modules/.pnpm/@pinia+nuxt@0.5.1_rollup@4.17.2_typescript@5.4.5_vue@3.4.27/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/opt/build/repo/.nuxt/components.plugin.mjs";
import prefetch_client_sEP5WvQc9N from "/opt/build/repo/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@unocss+reset@0.60.2_eslint@8.57.0_floating-vue@5.2.2_ro_qhwxw25worliosedcaoyxxe6ae/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_pZUe1gLKAd from "/opt/build/repo/node_modules/.pnpm/@productdevbook+chatwoot@1.5.0_rollup@4.17.2_vue@3.4.27/node_modules/@productdevbook/chatwoot/dist/runtime/plugin.mjs";
import slideovers_2PNyJy80EZ from "/opt/build/repo/node_modules/.pnpm/@nuxt+ui@2.16.0_nuxt@3.11.2_rollup@4.17.2_vite@5.2.11_vue@3.4.27/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.mjs";
import modals_MjraGt6d5W from "/opt/build/repo/node_modules/.pnpm/@nuxt+ui@2.16.0_nuxt@3.11.2_rollup@4.17.2_vite@5.2.11_vue@3.4.27/node_modules/@nuxt/ui/dist/runtime/plugins/modals.mjs";
import colors_mB9lNo5Kzl from "/opt/build/repo/node_modules/.pnpm/@nuxt+ui@2.16.0_nuxt@3.11.2_rollup@4.17.2_vite@5.2.11_vue@3.4.27/node_modules/@nuxt/ui/dist/runtime/plugins/colors.mjs";
import plugin_client_0es7dvT31Z from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+color-mode@3.4.1_rollup@4.17.2/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.mjs";
import i18n_6zDN12cEjr from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+i18n@8.3.1_rollup@4.17.2_vue@3.4.27/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import chunk_reload_client_2VjqTFeNtV from "/opt/build/repo/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@unocss+reset@0.60.2_eslint@8.57.0_floating-vue@5.2.2_ro_qhwxw25worliosedcaoyxxe6ae/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import presets_FQRUZznk9H from "/opt/build/repo/node_modules/.pnpm/@nuxt+ui-pro@1.2.0_nuxt@3.11.2_rollup@4.17.2_vite@5.2.11_vue@3.4.27/node_modules/@nuxt/ui-pro/plugins/presets.ts";
import scrollbars_client_lSPFNhROf0 from "/opt/build/repo/node_modules/.pnpm/@nuxt+ui-pro@1.2.0_nuxt@3.11.2_rollup@4.17.2_vite@5.2.11_vue@3.4.27/node_modules/@nuxt/ui-pro/plugins/scrollbars.client.ts";
import variables_gc9WsAcVVP from "/opt/build/repo/node_modules/.pnpm/@nuxt+ui-pro@1.2.0_nuxt@3.11.2_rollup@4.17.2_vite@5.2.11_vue@3.4.27/node_modules/@nuxt/ui-pro/plugins/variables.ts";
import api_GFfDXud5Cr from "/opt/build/repo/plugins/api.ts";
import my_directives_client_77C0dntRRQ from "/opt/build/repo/plugins/my_directives.client.ts";
export default [
  revive_payload_client_o0iMtc9qUL,
  unhead_3G0j3ejbZm,
  router_NbKK8gYbQ8,
  payload_client_UsyfO2JDPg,
  check_outdated_build_client_y8nMEe2xZO,
  plugin_vue3_TQ69XjtBfj,
  components_plugin_KR1HBZs4kY,
  prefetch_client_sEP5WvQc9N,
  plugin_pZUe1gLKAd,
  slideovers_2PNyJy80EZ,
  modals_MjraGt6d5W,
  colors_mB9lNo5Kzl,
  plugin_client_0es7dvT31Z,
  i18n_6zDN12cEjr,
  chunk_reload_client_2VjqTFeNtV,
  presets_FQRUZznk9H,
  scrollbars_client_lSPFNhROf0,
  variables_gc9WsAcVVP,
  api_GFfDXud5Cr,
  my_directives_client_77C0dntRRQ
]