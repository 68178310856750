const useAuthState = () => useState<Api.User | null>("auth", () => null);

export const useAuth = () => {
  const authState = useAuthState();

  return {
    loggedIn: computed(() => Boolean(authState.value)),
    user: computed(() => authState.value || null),
    fetchUserInfo,
    logout,
  };
};

const fetchUserInfo = async () => {
  useAuthState().value = await useNuxtApp()
    .$api("/user")
    .catch(() => null);
};

async function logout() {
  useAuthState().value = null;
  useCookie("auth:token").value = null;
  await nextTick();
  navigateTo("/login");
}
